<template>
  <v-navigation-drawer app permanent clipped expand-on-hover>
    <v-list dense nav>
      <v-list-item v-for="item in menuItems" :key="item.title" :to="item.link" link color="primary">
        <v-list-item-icon>
          <v-badge v-if="item.title == 'alerts' && unseenAlertsCount" dot color="secondary">
            <v-icon>mdi-{{ item.icon }}</v-icon>
          </v-badge>
          <v-icon v-else>mdi-{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t(item.title) | capitalize }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    menuItems: [
      { title: "home", icon: "home", link: "/" },
      { title: "alerts", icon: "bell-alert", link: "/alerts" },
      { title: "rules", icon: "pencil-ruler", link: "/rules" },
      { title: "documentation", icon: "bookshelf", link: "/documentation" },
      { title: "faq", icon: "frequently-asked-questions", link: "/faq" },
      { title: "contactus", icon: "message", link: "/contact" },
    ],
  }),
  computed: {
    unseenAlertsCount() {
      return this.$store.state.alerts.unseenAlertsCount;
    },
  },
};
</script>

<style>
#app .v-navigation-drawer__border {
  display: none;
}
</style>
